const routes = {
    about: '/about',
    contact: '/contact',
    privacy: '/privacy',
    admin: {
        base: '/admin',
        dashboard: '/admin/dashboard',
        maps: '/admin/maps',
        settings: '/admin/settings',
        tables: '/admin/tables',
        weather: '/admin/weather',
    },
    auth: {
        base: '/auth',
        forgotPassword: '/auth/forgot',
        login: '/auth/login',
        register: '/auth/register',
        verify: '/auth/verify',
    }
}

export default routes;
