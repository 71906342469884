import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import HeaderStats from "../components/Headers/HeaderStats.js";
import FooterAdmin from "../components/Footers/FooterAdmin.js";

// views

import Dashboard from "../views/admin/Dashboard.js";
import Maps from "../views/admin/Maps.js";
import Settings from "../views/admin/Settings.js";
import Tables from "../views/admin/Tables.js";
import routes from "../routes";
import WeatherDashboard from "../views/admin/WeatherDashboard.js";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path={routes.admin.dashboard} exact component={Dashboard} />
            <Route path={routes.admin.maps} exact component={Maps} />
            <Route path={routes.admin.settings} exact component={Settings} />
            <Route path={routes.admin.tables} exact component={Tables} />
            <Route
              path={routes.admin.weather}
              exact
              component={WeatherDashboard}
            />
            <Redirect from={routes.admin.base} to={routes.admin.dashboard} />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
