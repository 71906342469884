import React, { useEffect } from "react";
import WeatherCard from "../../components/Cards/WeatherCard";
import currentData from "../../MockData/currentWeather";

function WeatherDashboard() {
  useEffect(() => {
    console.log(currentData);
  }, []);
  return (
    <>
      <ul className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2">
        {currentData.items.map((item, i) => (
          <li className="" key={i}>
            <WeatherCard name={item.name} value={item.value} unit={item.unit} />
          </li>
        ))}
      </ul>
    </>
  );
}

export default WeatherDashboard;
