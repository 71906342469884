export const COGNITO_ERROR_CODES = {
    INVALID_PASSWORD: 'InvalidPasswordException',
    ACCOUNT_EXISTS: 'UsernameExistsException',
  } as const;

type CognitoErrorCodes = typeof COGNITO_ERROR_CODES;
type CognitoErrorCode = CognitoErrorCodes[keyof CognitoErrorCodes];

export type CognitoError = {
    code: CognitoErrorCode,
    message: string,
  }
