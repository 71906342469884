const currentData = {
  properties: {
    ok: 1,
    error: null,
    count: 12,
    index: 1,
    total: 1,
  },
  items: [
    {
      id: "synders_0766BC",
      measure: "temperature",
      name: "Air Temperature",
      value: 17.57,
      unit: "C",
      lastupdate: "2021-07-11T03:31:53",
      iscurrent: true,
    },
    {
      id: "synders_0766BC",
      measure: "groundtemperature",
      name: "Ground Temperature",
      value: 15.69,
      unit: "C",
      lastupdate: "2021-07-11T03:31:53",
      iscurrent: true,
    },
    {
      id: "synders_0766BC",
      measure: "humidity",
      name: "Humidity",
      value: 72.17,
      unit: "%",
      lastupdate: "2021-07-11T03:31:53",
      iscurrent: true,
    },
    {
      id: "synders_0766BC",
      measure: "lightlevel",
      name: "Light Level",
      value: 0,
      unit: "lux",
      lastupdate: "2021-07-11T03:31:53",
      iscurrent: true,
    },
    {
      id: "synders_0766BC",
      measure: "mm_per_hour_rain",
      name: "MM Per Hour Rain",
      value: 0,
      unit: "mm",
      lastupdate: "2021-07-11T03:31:52",
      iscurrent: true,
    },
    {
      id: "synders_0766BC",
      measure: "event_acc_rain",
      name: "Event Rain",
      value: 0,
      unit: "mm",
      lastupdate: "2021-07-11T03:31:52",
      iscurrent: true,
    },
    {
      id: "synders_0766BC",
      measure: "groundmoisture",
      name: "Ground Moisture",
      value: 437,
      unit: "Value",
      lastupdate: "2021-07-11T03:31:53",
      iscurrent: true,
    },
    {
      id: "synders_0766BC",
      measure: "pressure",
      name: "Pressure",
      value: 1016.06,
      unit: "hpa",
      lastupdate: "2021-07-11T03:31:53",
      iscurrent: true,
    },
    {
      id: "synders_0766BC",
      measure: "humidex",
      name: "Humidex",
      value: 19.84,
      unit: "C",
      lastupdate: "2021-07-11T03:32:41",
      iscurrent: true,
    },
    {
      id: "synders_0766BC",
      measure: "dewpoint",
      name: "Dewpoint",
      value: 12,
      unit: "C",
      lastupdate: "2021-07-11T03:32:42",
      iscurrent: true,
    },
    {
      id: "synders_0766BC",
      measure: "rain1hr",
      name: "Rain 1hr",
      value: 0,
      unit: "mm",
      lastupdate: "2021-07-11T03:32:42",
      iscurrent: true,
    },
    {
      id: "synders_0766BC",
      measure: "rain24hr",
      name: "Rain 24hr",
      value: 0,
      unit: "mm",
      lastupdate: "2021-07-11T03:32:43",
      iscurrent: true,
    },
  ],
  links: {
    self: {
      href: "/weather/sensor/synders_0766BC/last",
    },
    first: null,
    last: null,
    next: null,
    prev: null,
  },
};

export default currentData;
