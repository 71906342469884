import React from "react";

import Footer from "../components/Footers/Footer";
import Navbar from "../components/Navbars/AuthNavbar.js";

export default function Landing() {
  return (
    <>
      <Navbar />

      <main className="flex-1 pt-32 pb-32">
        <div className="container mx-auto px-4 flex">
          <div className="px-4 w-full">
            <div className="flex flex-wrap -mx-4 mt-12 justify-center items-center">
              <div className="px-4 relative w-full lg:w-3/12 w-full md:w-6/12 w-6/12 border-r">
                <div className="text-center py-6">
                  <div className="text-white bg-lightBlue-500 shadow-lg rounded rounded-full justify-center items-center inline-flex text-center p-2 mb-6 w-16 h-16">
                    <i className="fas fa-map-marker-alt text-lg"></i>
                  </div>
                  <h4 className="text-xl font-bold leading-tight mt-0 mb-2">
                    Address
                  </h4>
                  <div className="mb-4">
                    <p className="text-blueGray-500">Waterloo, ON</p>
                  </div>
                </div>
              </div>
              <div className="px-4 relative w-full lg:w-3/12 w-full md:w-6/12 w-6/12 border-r">
                <div className="text-center py-6">
                  <div className="text-white bg-lightBlue-500 shadow-lg rounded rounded-full justify-center items-center inline-flex text-center p-2 mb-6 w-16 h-16">
                    <i className="fas fa-envelope text-lg"></i>
                  </div>
                  <h4 className="text-xl font-bold leading-tight mt-0 mb-2">
                    Email
                  </h4>
                  <div className="mb-4">
                    <p className="text-blueGray-500">info@trailrabbit.io</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}
