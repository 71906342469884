import React from "react";

function WeatherCard({ id, name, value, unit, lastupdate }) {
  return (
    <div className="relative flex flex-col break-words shadow-lg rounded bg-blueGray-700 rounded-t px-4 py-3 ">
      <p className="text-white text-3xl text-center">
        {value} {unit}
      </p>
      <h3 className="text-white text-xl text-center">{name}</h3>
    </div>
  );
}

export default WeatherCard;
